<p-sidebar [(visible)]="visible" position="bottom" (onHide)="onHide()">
  <ng-template pTemplate="header">
    <h5 class="m-0 ml-2">{{ alerts.stepHeader() }}</h5>
  </ng-template>

  <twx-select-alert-type
    (selectedOption)="navigateToAlertMobileCreationFlow($event)"
  ></twx-select-alert-type>

  <ng-template pTemplate="footer">
    <div class="p-2">
      <twx-button-outlined
        label="Close"
        type="button"
        class="w-full"
        (buttonClicked)="onHide()"
      ></twx-button-outlined>
    </div>
  </ng-template>
</p-sidebar>
