export enum AlertStatus {
  Pending = "pending",
  Created = "created",
  Resolved = "resolved",
}

export enum AlertType {
  NeedMoreProduct = "nmp",
  ServiceIssue = "service",
  EquipmentIssue = "equipment",
  CreditIssue = "credit",
}
