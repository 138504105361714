import { Component, EventEmitter, Output } from "@angular/core";
import { AlertType } from "../../utils/alert.enum";

@Component({
  selector: "twx-select-alert-type",
  standalone: true,
  imports: [],
  templateUrl: "./select-alert-type.component.html",
  styleUrl: "./select-alert-type.component.scss",
})
export class SelectAlertTypeComponent {
  readonly alertTypeEnum = AlertType;

  @Output() selectedOption = new EventEmitter<AlertType>();

  next(step: AlertType): void {
    this.selectedOption.emit(step);
  }
}
