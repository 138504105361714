<article class="select-alert-type">
  <ul class="select-alert-type__ul">
    <li
      (click)="next(alertTypeEnum.NeedMoreProduct)"
      (keydown.enter)="next(alertTypeEnum.NeedMoreProduct)"
    >
      Need More Product
    </li>
    <li
      (click)="next(alertTypeEnum.ServiceIssue)"
      (keydown.enter)="next(alertTypeEnum.ServiceIssue)"
    >
      Service Issue
    </li>
    <li
      (click)="next(alertTypeEnum.EquipmentIssue)"
      (keydown.enter)="next(alertTypeEnum.EquipmentIssue)"
    >
      Equipment Issue
    </li>
    <li
      (click)="next(alertTypeEnum.CreditIssue)"
      (keydown.enter)="next(alertTypeEnum.CreditIssue)"
    >
      Credit Issue
    </li>
  </ul>
</article>
