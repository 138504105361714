import { Component, inject } from "@angular/core";
import { SidebarModule } from "primeng/sidebar";
import { SelectAlertTypeComponent } from "../../components/select-alert-type/select-alert-type.component";
import { AlertsService } from "../../services/alerts.service";
import { ButtonOutlinedComponent } from "../../../../shared/components/button-outlined/button-outlined.component";
import { AlertType } from "../../utils/alert.enum";

@Component({
  selector: "twx-alert-type-mobile-selection",
  standalone: true,
  imports: [SidebarModule, SelectAlertTypeComponent, ButtonOutlinedComponent],
  templateUrl: "./alert-type-mobile-selection.component.html",
  styleUrl: "./alert-type-mobile-selection.component.scss",
})
export class AlertTypeMobileSelectionComponent {
  visible = true;

  readonly alerts = inject(AlertsService);

  onHide() {
    this.visible = false;
  }

  navigateToAlertMobileCreationFlow(alertType: AlertType) {
    this.alerts.navigateToMobileAlertCreationFlow(alertType);
    this.onHide();
  }
}
